import React from "react";
import {Statistic} from "antd";
import {StatisticProps} from "antd/lib/statistic/Statistic";

type Property = StatisticProps & {}

type State = {}

export default class Indicator extends React.Component<Property, State> {
    render() {
        return (
            <Statistic {...this.props} valueStyle={{fontSize: '13px'}}/>
        );
    }
}
