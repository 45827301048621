import {Article} from "../model/Article";
import {ArticlesData} from "../model/ArticlesData";

export class ArticleService {
    getArticle(id: number): Promise<Article> {
        return fetch(`https://contabilidadygestion.cl/article_${id}.json`, {mode: 'cors'})
            .then(data => data.json());
    }

    getAll(): Promise<ArticlesData> {
        return fetch(`https://contabilidadygestion.cl/articles_data.json`, {mode: 'cors'})
            .then(data => data.json());
    }
}
