import React from 'react';
import logo from '../assets/logo.png';
import style from './Header.module.css';
import {HomeFilled, MailFilled} from '@ant-design/icons';
import CgMenuButton from '../common/CgMenuButton';
import IndicatorsBar from '../statistics/IndicatorsBar';
import {Button} from 'antd';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import RouterService from '../common/RouterService';
import ContactButton from '../contact/ContactButton';

type Property = RouteComponentProps & {};

type State = {};

class Header extends React.Component<Property, State> {

    private routerService: RouterService = new RouterService(this);

    render() {
        return (
            <div className={style.header}>
                <div className={style.top}>
                    <a href={'/#'} onClick={() => this.routerService.goHome()}>
                        <img className={style.logo}
                             src={logo}
                             alt={'Contabilidad & Gestión'}/>
                    </a>
                    <div className={style.topButtons}>
                        <Button type={'text'}
                                icon={<HomeFilled/>}
                                onClick={() => this.routerService.goHome()}>Inicio</Button>
                        <ContactButton type={'text'}
                                       icon={<MailFilled/>}/>
                    </div>
                </div>
                <IndicatorsBar/>
                <div className={style.buttons}>
                    <CgMenuButton isSelected={this.routerService.isArticles()}
                                  onClick={() => this.routerService.goArticles()}>Artículos</CgMenuButton>
                    <CgMenuButton isSelected={this.routerService.isServices()}
                                  onClick={() => this.routerService.goServices()}>Servicios</CgMenuButton>
                    <CgMenuButton isSelected={this.routerService.isAbout()}
                                  onClick={() => this.routerService.goAbout()}>¿Quienes Somos?</CgMenuButton>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
