import React from 'react';
import home_1 from '../assets/home_1.jpg';

type Property = {};

type State = {};

export default class HomeSection extends React.Component<Property, State> {
    render() {
        return (
            <img src={home_1} alt={'home_image'} style={{width: '100%'}}/>
        );
    }
}
