import React from "react";
import {RouteComponentProps} from "react-router-dom";

enum Route {
    ABOUT = 'about',
    ARTICLES = 'articles',
    SERVICES = 'services',
    HOME = ''
}

export default class RouterService {
    private component: React.Component<RouteComponentProps<any>, any>;

    constructor(component: React.Component<RouteComponentProps<any>, any>) {
        this.component = component;
    }

    goAbout(): void {
        this.goTo(Route.ABOUT);
    }

    goArticles(): void {
        this.goTo(Route.ARTICLES);
    }

    goServices(): void {
        this.goTo(Route.SERVICES);
    }

    goHome(): void {
        this.goTo(Route.HOME);
    }

    goArticle(article: number): void {
        this.goTo(`${Route.ARTICLES}/${article}`);
    }

    private goTo(route: string) {
        this.component.props.history.push(`/${route}`);
    }

    isAbout(): boolean {
        return this.isPath(Route.ABOUT);
    }

    isArticles(): boolean {
        return this.isPath(Route.ARTICLES);
    }

    isServices(): boolean {
        return this.isPath(Route.SERVICES);
    }

    isHome(): boolean {
        return this.isPath(Route.HOME);
    }

    private isPath(route: Route): boolean {
        return window.location.pathname.match(`^\\/${route}((\\?.*)?|\\/.*)$`) != null;
    }
}
