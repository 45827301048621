import React from 'react';
import {Typography} from 'antd';
import style from './About.module.css';
import home_1 from "../assets/home_1.jpg";

const {Paragraph, Title} = Typography;

type Property = {}

type State = {}

export default class About extends React.Component<Property, State> {
    render() {
        return (
            <div>
                <Title className={style.title} level={2}>Sobre Nosotros</Title>
                <img src={home_1} alt={'home_image'} style={{width: '100%', marginBottom: '20px'}}/>
                <Paragraph style={{textAlign: 'justify'}}>
                    <strong>Contabilidad & Gestión</strong> está integrada por un grupo de
                    profesionales con vasta experiencia en el ámbito de la educación, las finanzas y la esfera legal
                    quienes al igual que todos los chilenos y chilenas somos conscientes de la nueva realidad nacional y
                    su proceso de cambio.
                </Paragraph>
                <Paragraph style={{textAlign: 'justify'}}>
                    Coincidimos que a partir de la expresión democrática de millones de chilenos nuestra visión como
                    sociedad debe cambiar. Cada día nuestro país no solo requiere de profesionales altamente
                    capacitados, sino que, además, con un alto espíritu de solidaridad, específicamente con
                    instituciones orientadas a fortalecer y entregar apoyo a niños y niñas vulnerables que por distintas
                    circunstancias de la vida les es más difícil integrarse a este sistema y también a personas que
                    requieren de un apoyo profesional accesible.
                </Paragraph>
                <Paragraph style={{textAlign: 'justify'}}>
                    Donde se administran escasos recursos para lograr optimizar sus gestiones en la búsqueda del éxito
                    de sus procesos según la naturaleza de su persona jurídica, no solo se va a requerir del esfuerzo
                    propio, sino que además de un apoyo externo que entienda la problemática que se requiera resolver y
                    se les otorgue toda la experiencia a un costo razonable y justo.
                </Paragraph>
                <Paragraph style={{textAlign: 'justify'}}>
                    Ninguna organización podría mantenerse en el tiempo sin generar los recursos que les permita
                    mantenerse en el tiempo, menos aún aquella que busca otorgar un servicio de calidad a un costo
                    razonable para quienes no gestionan grandes recursos asegurando la permanencia hasta el anhelado
                    éxito.
                </Paragraph>
                <Paragraph style={{textAlign: 'justify'}}>
                    Por lo anterior, la optimización de los recursos debe ser a todo nivel, sea para quien presta el
                    servicio como para quien lo contrata de manera de contrastar la satisfacción que otorga el trabajo y
                    el apoyo eficiente al trabajador, al profesional y /o el emprendedor.
                </Paragraph>
                <Paragraph style={{textAlign: 'justify'}}>
                    <strong>Contabilidad & Gestión</strong> lo componen en un comienzo un profesor con amplia trayectoria en la educación
                    quien ha ejercido por décadas y con gran éxito en calidad de director.
                </Paragraph>
                <Paragraph style={{textAlign: 'justify'}}>
                    Un abogado con vasta experiencia en lo penal, laboral y tributario y un Contador Auditor con amplia
                    trayectoria en la administración, sobre todo, lo concerniente a la
                    educación ya sea en el ámbito de la gestión, la contabilidad, legislación tributaria y las finanzas.
                </Paragraph>
            </div>
        );
    }
}
