import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Descriptions, Divider, Typography} from 'antd';
import ContactButton from '../contact/ContactButton';
import {MailFilled} from '@ant-design/icons';
import style from './Article.module.css';
import {Article} from "../model/Article";
import {ArticleService} from "./ArticleService";

const {Title, Paragraph} = Typography;

type Property = {
    article: number
} & RouteComponentProps;

type State = {
    article?: Article
}

class ArticleView extends React.Component<Property, State> {

    private readonly articleService: ArticleService = new ArticleService();

    constructor(props: Property) {
        super(props);
        this.state = {article: undefined};
    }

    componentDidMount() {
        const {article} = this.props;
        this.articleService.getArticle(article)
            .then(response => this.setState({article: response}));
    }

    render() {
        const articleObj: Article | undefined = this.state.article;
        return (
            <div>
                <Title className={style.title} level={2}>{articleObj?.title}</Title>
                <Divider/>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '20px'}}>
                    <Paragraph style={{textAlign: 'justify'}} italic={true}>{articleObj?.review}</Paragraph>
                    <div style={{borderLeftStyle: 'solid', borderLeftColor: 'lightgray', borderLeftWidth: '1px'}}>
                        <Descriptions title="Autor"
                                      size='small'
                                      column={2}
                                      style={{paddingLeft: '10px'}}
                                      layout='vertical'>
                            <Descriptions.Item label="Nombre"
                                               contentStyle={{color: 'gray'}}>Enrique López</Descriptions.Item>
                            <Descriptions.Item label="Estudios"
                                               contentStyle={{color: 'gray'}}>Contador Auditor</Descriptions.Item>
                            <Descriptions.Item label="Teléfono"
                                               contentStyle={{color: 'gray'}}>+569 181 000 00</Descriptions.Item>
                            <Descriptions.Item label="Ubicación"
                                               contentStyle={{color: 'gray'}}>Santiago, Chile 🇨🇱</Descriptions.Item>
                            <Descriptions.Item>
                                <ContactButton type={'text'}
                                               icon={<MailFilled/>}
                                               prefilledMessage={'Hola, respecto a ' + articleObj?.title}/>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
                <Divider/>
                {articleObj?.content.map((paragraph, index) => (
                    <Paragraph style={{textAlign: 'justify'}}
                               key={index}>{paragraph}</Paragraph>
                ))}
            </div>
        );
    }
}

export default withRouter(ArticleView);
