import React from 'react';
import {Typography} from 'antd';
import style from './ServiceSection.module.css';

const {Title, Paragraph} = Typography;

type Property = {}

type State = {}

export default class ServiceSection extends React.Component<Property, State> {
    render() {
        return (
            <div>
                <Title className={style.title} level={2}>Nuestros Servicios</Title>
                <Title level={3}>Asesoría educacional</Title>
                <Paragraph style={{textAlign: 'justify'}}>Nam scelerisque vestibulum venenatis. Aenean quis odio vel
                    nibh mattis euismod non a tellus.
                    Pellentesque feugiat consectetur nunc id blandit. In hac habitasse platea dictumst. Pellentesque
                    ullamcorper, lectus et efficitur lacinia, enim tellus cursus elit, eget semper justo eros ac tellus.
                    Phasellus id commodo diam. Aenean tempor, risus sit amet tristique ornare, tortor purus mattis est,
                    id ultricies magna neque blandit nulla. Praesent scelerisque, orci non dignissim pulvinar, nulla est
                    pretium dolor, ac malesuada nibh mi vel massa. Pellentesque porttitor est at tellus pulvinar ornare.
                    Fusce eu ipsum felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
                <Title level={3}>Asesoría legal</Title>
                <Paragraph style={{textAlign: 'justify'}}>Curabitur nec pretium lectus, id vestibulum nunc. Aliquam
                    sagittis accumsan ultrices. Duis
                    nibh elit, dictum nec tincidunt convallis, sollicitudin at urna. Aliquam ullamcorper tortor maximus
                    odio scelerisque sodales. Aliquam sollicitudin mollis sapien in dapibus. Etiam vitae ex sed ligula
                    vestibulum lobortis et in nibh. In sit amet erat consequat ex tristique euismod. Aliquam
                    sollicitudin dui diam, eget vestibulum massa pretium ut. Maecenas sapien augue, pulvinar sed turpis
                    vel, pharetra suscipit tortor. Nullam vitae est at urna tincidunt porta vitae imperdiet
                    dui.</Paragraph>
                <Title level={3}>Asesoría financiera</Title>
                <Paragraph style={{textAlign: 'justify'}}>Sed vel aliquam arcu. Maecenas leo nunc, tempus sed lorem at,
                    sollicitudin interdum metus.
                    Nam egestas suscipit bibendum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris
                    accumsan quam et nibh faucibus, ac tristique mi blandit. Cras accumsan id turpis vel rutrum. Sed
                    cursus neque et iaculis suscipit. Donec lorem metus, vulputate vel erat ut, semper mollis leo. Sed
                    ac ante ut augue dictum posuere. Sed tincidunt sapien ut mauris scelerisque convallis. Etiam eu
                    ornare massa, vel elementum est. Nulla in lobortis nibh. Aenean mollis commodo ipsum in
                    tincidunt.</Paragraph>
            </div>
        );
    }
}
