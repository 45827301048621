import React from "react";
import Indicator from "../common/Indicator";
import style from './IndicatorsBar.module.css';
import IndicatorService from "../header/service/IndicatorService";

interface Property {

}

interface State {
    statistics: any;
}

export default class IndicatorsBar extends React.Component<Property, State> {

    private indicatorService: IndicatorService = new IndicatorService();

    constructor(props: Property) {
        super(props);
        this.state = {statistics: {}};
    }

    componentDidMount() {
        this.indicatorService.getIndicators()
            .then(data => this.setState({statistics: data}));
    }

    render() {
        const {statistics} = this.state;
        return (
            <div className={style.statistics}>
                <Indicator title="Dolar"
                           prefix={'$'}
                           decimalSeparator={','}
                           groupSeparator={'.'}
                           value={statistics.dolar?.valor}
                           valueStyle={{fontSize: '13px'}}
                           loading={!statistics.dolar?.valor}/>
                <Indicator title="Euro"
                           prefix={'$'}
                           decimalSeparator={','}
                           groupSeparator={'.'}
                           value={statistics.euro?.valor}
                           valueStyle={{fontSize: '13px'}}
                           loading={!statistics.euro?.valor}/>
                <Indicator title="UF"
                           prefix={'$'}
                           decimalSeparator={','}
                           groupSeparator={'.'}
                           value={statistics.uf?.valor}
                           valueStyle={{fontSize: '13px'}}
                           loading={!statistics.uf?.valor}/>
                <Indicator title="UTM"
                           prefix={'$'}
                           decimalSeparator={','}
                           groupSeparator={'.'}
                           value={statistics.utm?.valor}
                           valueStyle={{fontSize: '13px'}}
                           loading={!statistics.utm?.valor}/>
                <Indicator title="IPC"
                           suffix={'%'}
                           decimalSeparator={','}
                           groupSeparator={'.'}
                           value={statistics.ipc?.valor}
                           valueStyle={{fontSize: '13px'}}
                           loading={!statistics.ipc?.valor}/>
            </div>
        );
    }
}
