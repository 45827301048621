import React from 'react';
import {Button, ButtonProps, Drawer, Form, Input} from "antd";

type Property = { prefilledMessage?: string } & ButtonProps;

type State = { showContactForm: boolean };

export default class ServiceSection extends React.Component<Property, State> {

    constructor(props: Property) {
        super(props);
        this.state = {showContactForm: false};
        this.openContactForm = this.openContactForm.bind(this);
        this.closeContactForm = this.closeContactForm.bind(this);
    }

    openContactForm(): void {
        this.setState({showContactForm: true});
    }

    closeContactForm(): void {
        this.setState({showContactForm: false});
    }

    getButtonProps(): ButtonProps {
        const copy: Property = Object.assign({}, this.props);
        delete copy.prefilledMessage;
        return copy as ButtonProps;
    }

    render() {
        const validateMessages = {
            required: 'Campo es requerido.',
            types: {
                email: 'No es un email válido.',
            }
        };
        return (
            <div>
                <Button {...this.getButtonProps()} onClick={this.openContactForm}>Contactar</Button>
                <Drawer title='Contactar'
                        placement='bottom'
                        closable={false}
                        height={'auto'}
                        onClose={this.closeContactForm}
                        visible={this.state.showContactForm}>
                    <Form layout='vertical' name="contact-form"
                          onFinish={(val) => alert('onFInish' + JSON.stringify(val))}
                          validateMessages={validateMessages}>
                        <Form.Item name={['contact', 'name']}
                                   label="Nombre"
                                   rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['contact', 'email']}
                                   label="Email"
                                   rules={[{type: 'email', required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['contact', 'message']}
                                   label="Mensaje"
                                   initialValue={this.props.prefilledMessage}>
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="text"
                                    onClick={this.closeContactForm}>Cancelar</Button>
                            <Button type="default"
                                    htmlType='submit'>Enviar</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        );
    }
}
