import React from "react";
import {Button, ButtonProps} from "antd";

type Property = ButtonProps & {
    isSelected?: boolean
}

type State = {}

export default class CgMenuButton extends React.Component<Property, State> {

    getBtnProperties(): Property {
        const btnProps: Property = Object.assign({}, this.props);
        delete btnProps.isSelected;
        return btnProps;
    }

    render() {
        const btnProps: Property = this.getBtnProperties();
        const {isSelected} = this.props;
        return (
            <div style={{borderBottomStyle: isSelected ? 'solid' : 'none'}}>
                <Button {...btnProps}
                        type={'text'}
                        style={{width: '100%', color: isSelected ? 'black' : 'gray'}}>{this.props.children}</Button>
            </div>
        );
    }
}
