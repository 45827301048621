import React from 'react';
import {Card, Typography} from 'antd';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import RouterService from '../common/RouterService';
import {Article} from "../model/Article";
import {ArticleService} from "./ArticleService";

const {Title, Paragraph, Text} = Typography;

type Property = {
    article: number
} & RouteComponentProps;

type State = {
    article?: Article
}

class ArticleReview extends React.Component<Property, State> {

    private readonly routerService: RouterService = new RouterService(this);
    private readonly articleService: ArticleService = new ArticleService();

    constructor(props: Property) {
        super(props);
        this.state = {article: undefined};
        this.openArticle = this.openArticle.bind(this);
    }

    componentDidMount() {
        this.articleService.getArticle(this.props.article)
            .then(article => this.setState({article}));
    }

    openArticle(): void {
        this.routerService.goArticle(this.props.article);
    }

    render() {
        const articleObj = this.state.article;
        return (
            <div>
                <Card bordered={false} hoverable={true} onClick={this.openArticle}>
                    <Title level={4}>{articleObj?.title}</Title>
                    <Paragraph style={{textAlign: 'justify'}}>{articleObj?.review}</Paragraph>
                    <Text italic={true} underline={true}>continuar leyendo ...</Text>
                </Card>
            </div>
        );
    }
}

export default withRouter(ArticleReview);
