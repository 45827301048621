import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Home from './home/Home';
import 'antd/dist/antd.css';
import './index.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={Home}/>
                <Route path='/about' exact component={Home}/>
                <Route path='/articles' exact component={Home}/>
                <Route path='/articles/:id' exact render={(props) => <Home article={parseInt(props.match.params.id)}/>}/>
                <Route path='/services' exact component={Home}/>
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
