import React from 'react';
import style from './ArticleSection.module.css';
import ArticleReview from "./ArticleReview";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Typography} from 'antd';
import {ArticlesData} from "../model/ArticlesData";
import {ArticleService} from "./ArticleService";

const {Title} = Typography;

type Property = {} & RouteComponentProps;

type State = {
    articlesData: ArticlesData
};

class ArticleSection extends React.Component<Property, State> {

    private readonly articleService: ArticleService = new ArticleService();

    componentDidMount() {
        this.articleService.getAll()
            .then(data => this.setState({articlesData: data}));
    }

    render() {
        return (
            <div className={style.articles}>
                <Title className={style.title} level={2}>Publicaciones</Title>
                {this.state?.articlesData?.articles.map(article => <ArticleReview article={article.id}
                                                                                key={article.id}/>)}
            </div>
        );
    }
}

export default withRouter(ArticleSection);
