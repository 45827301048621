import React from 'react';
import Header from '../header/Header';
import style from './Home.module.css';
import RouterService from "../common/RouterService";
import {RouteComponentProps, withRouter} from "react-router-dom";
import ServiceSection from "../services/ServiceSection";
import ArticleSection from "../articles/ArticleSection";
import About from '../about/About';
import HomeSection from "./HomeSection";
import ArticleView from "../articles/ArticleView";

type Property = RouteComponentProps & {
    article?: number
};

type State = {}

class Home extends React.Component<Property, State> {

    private routerService: RouterService = new RouterService(this);

    render() {
        const {article} = this.props;
        let articleSection = <ArticleSection/>;
        if (article !== undefined) {
            articleSection = <ArticleView article={article}/>;
        }
        return (
            <div className={style.home}>
                <div className={style.header}>
                    <Header/>
                    <div className={style.section}>
                        {this.routerService.isAbout() && <About/>}
                        {this.routerService.isArticles() && articleSection}
                        {this.routerService.isServices() && <ServiceSection/>}
                        {this.routerService.isHome() && <HomeSection/>}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);
